<template>
    <div class="main-header">
        <div class="logo" @click="$router.push('/')"></div>
        <template v-if="showMainNav && $store.state.roleType">
            <Dropdown class="drop-down-nav" trigger="hover" v-if="$store.state.teamsData && $store.state.teamsData.length > 0">
                <SvgIcon name="management" hoverColor="#2b32c9" :size="28" textColor="#666" :class="['nav-item']" :text="teamName"></SvgIcon>
                <template #content>
                    <template v-for="team in $store.state.teamsData" :key="team.id">
                        <Doption>
                            <div @click="changeTeam(team.id)" class="team-option">
                                <SvgIcon name="team" class="team-icon" color="#2bc954" :size="20" v-if="team.ownerFlag" />
                                <span :class="['team-option-text', 'ellipsis', { select: team.id === $store.state.userData.teamId }]" :title="team.teamName">
                                    {{ team.teamName }}
                                </span>
                                <SvgIcon name="select" class="team-select" color="#2bc954" :size="20" v-if="team.id === $store.state.userData.teamId" />
                            </div>
                        </Doption>
                    </template>
                </template>
            </Dropdown>
            <template v-for="item in $store.state.navMap" :key="item.title">
                <Dropdown v-if="item.child && $store.getters.hasViewPermission(item.url)" class="drop-down-nav" :trigger="['hover', 'click']">
                    <router-link :to="item.redirect" :class="['nav-item', { active: $route.path.includes(item.url) }]">
                        {{ $t(item.title) }}
                    </router-link>
                    <template #content>
                        <template v-for="child in item.child">
                            <Doption v-if="$store.getters.hasViewPermission(child.url)" :key="child.title">
                                <router-link :to="child.url">{{ $t(child.title) }} </router-link>
                            </Doption>
                        </template>
                    </template>
                </Dropdown>
                <template v-else>
                    <router-link v-if="$store.getters.hasViewPermission(item.url)" :to="item.url" :class="['nav-item', { active: $route.path === item.url }]">
                        {{ $t(item.title) }}
                    </router-link>
                </template>
            </template>
        </template>

        <slot name="help-nav"></slot>
        <Space :size="40" class="right-tool">
            <Lang v-if="showLang" />
            <Dropdown class="drop-down-nav" trigger="hover" v-if="showUserNav && $store.state.userData && $store.state.userData.username">
                <SvgIcon
                    name="user"
                    hoverColor="#2b32c9"
                    :size="28"
                    textColor="#666"
                    :class="['nav-item', { active: $route.path.includes('personal') }]"
                    :text="$store.state.userData.username"></SvgIcon>
                <template #content>
                    <template v-for="item in $store.state.userMap">
                        <Doption v-if="$store.getters.hasViewPermission(item.url)" :key="item.title">
                            <a v-if="item.url === '/login'" @click="logoutFunc">{{ $t(item.title) }} </a>
                            <router-link v-else :to="item.url" :target="item.target">{{ $t(item.title) }} </router-link>
                        </Doption>
                    </template>
                </template>
            </Dropdown>
        </Space>
    </div>
</template>

<script>
import { defineComponent, inject, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Role } from '@/store'
import Lang from '@/components/Lang.vue'
export default defineComponent({
    name: 'main-header',
    components: {
        Lang
    },
    props: {
        showUserNav: {
            type: Boolean,
            default: true
        },
        showLang: {
            type: Boolean,
            default: true
        },
        showMainNav: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const t = inject('$t')
        const http = inject('$http')
        const store = useStore()
        const router = useRouter()
        const teams = ref()
        const logoutFunc = () => {
            http.account.logout().then(() => {
                store.commit('clearUserData')
                router.push('/login')
            })
        }
        const teamName = computed(() => {
            let team = store.state.teamsData.filter((item) => {
                return item.id === store.state.userData.teamId
            })
            if (team.length > 0) {
                team = team[0]
            } else {
                team = store.state.teamsData[0]
            }
            const userData = { ...store.state.userData }
            if (userData.role !== team.roleName) {
                userData.role = team.roleName
                store.commit('setUserData', userData)
            }
            return team.teamName
        })
        if (!store.getters.isRoleType(Role.Admin) && props.showMainNav) {
            store.dispatch('getTeamsData')
        }
        const changeTeam = (teamId) => {
            if (teamId === store.state.userData.teamId) return
            http.account.switchTeam({ targetTeamId: teamId }).then(() => {
                const userData = { ...store.state.userData }
                const team = store.state.teamsData.filter((item) => {
                    return item.id === teamId
                })[0]
                userData.teamId = team.id
                userData.role = team.roleName
                store.commit('setUserData', userData)
                if (router.currentRoute.value.fullPath !== '/project') {
                    router.push('/project')
                }
            })
        }
        return {
            logoutFunc,
            changeTeam,
            teams,
            teamName,
            t
        }
    }
})
</script>

<style lang="less">
.main-header {
    background-color: #fff;
    height: 68px;
    line-height: 68px;
    box-shadow: 0px 2px 6px 0px rgba(177, 177, 177, 0.25);
    .logo {
        margin: 19px 60px;
        float: left;
        height: 30px;
        width: 200px;
        color: #2b32c9;
        cursor: pointer;
        background-size: contain;
        background-image: url('~@/assets/img/logo.png');
        background-repeat: no-repeat;
    }
    .nav-item {
        font-size: 18px;
        color: #666666;
        margin: 0 20px;
        text-decoration: none;
        cursor: pointer;
        display: inline;
        &.active {
            position: relative;
            color: #2b32c9;
            font-weight: bold;
            outline: unset;
            &::after {
                position: absolute;
                bottom: -26px;
                left: 50%;
                content: '';
                width: 30px;
                margin-left: -15px;
                height: 4px;
                background: #2bc954;
                border-radius: 2px;
                z-index: 1;
            }
            &.icon-btn {
                svg {
                    fill: #2b32c9 !important;
                }
                span {
                    color: #2b32c9 !important;
                }
            }
        }
        &.icon-btn {
            height: 24px;
        }
    }
    .right-tool {
        float: right;
        margin-right: 100px;
    }
}
.team-option {
    padding: 0 10px;
    height: 36px;
    .team-icon {
        position: absolute;
        left: 12px;
    }
    .team-select {
        position: absolute;
        right: 12px;
    }
    .team-option-text {
        display: inline-block;
        padding: 0 30px;
        width: 200px;
        &.select {
            color: #2bc954;
        }
    }
}
</style>

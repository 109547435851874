<template>
    <div class="card-box">
        <div class="card-header" :style="paddingStyle" v-if="header">
            <slot name="tool-title">
                <span class="card-header-text">{{ text }}</span>
            </slot>
            <slot name="tool-bar"></slot>
        </div>
        <slot name="contain"></slot>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        padding: {
            type: Array,
            default: () => [40, 60, 0]
        },
        header: {
            type: Boolean,
            default: true
        },
        text: {
            type: String
        }
    },
    setup(props) {
        const paddingStyle =
            'padding:' +
            props.padding
                .map((item) => {
                    return typeof item === 'number' ? item + 'px' : item
                })
                .join(' ')
        return { paddingStyle }
    }
})
</script>

<style lang="less">
.card-box {
    height: 100%;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(177, 177, 177, 0.25);
    .card-header {
        height: 34;
        line-height: 34px;
        display: flex;
        justify-content: space-between;
        .card-header-text {
            padding-left: 14px;
            position: relative;
            font-size: 22px;
            height: 36px;
            color: #2b32c9;
            font-weight: bold;
            &::before {
                position: absolute;
                left: 0px;
                top: 3px;
                content: '';
                width: 3px;
                height: 26px;
                background-color: #2bc954;
                border-radius: 4px;
            }
        }
        .tab-bar {
            font-size: 18px;
            color: #2b32c9;
            cursor: pointer;
            span {
                position: relative;
                padding-right: 26px;
                font-weight: bold;
                &.active {
                    font-size: 22px;
                    &::before {
                        position: absolute;
                        left: 50%;
                        margin-left: -36px;
                        bottom: -12px;
                        content: '';
                        width: 40px;
                        height: 3px;
                        background-color: #2bc954;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div>
        <Header :showMainNav="false" :showUserNav="false" />
        <div class="forget-psw">
            <CardBox :text="t('login.retrievePassword')">
                <template #contain>
                    <Form
                        v-if="showFormBox"
                        ref="formDom"
                        class="form"
                        :model="formData"
                        :rules="formRule"
                        @keyup.enter="handleSubmit"
                        :label-col-props="{ span: 6 }"
                        :wrapper-col-props="{ span: 18 }">
                        <FormItem field="email" :label="t('login.email')" :validate-trigger="['blur']">
                            <Input v-model.trim="formData.email" spellcheck="false" :placeholder="t('holder.emailHolder')" />
                        </FormItem>
                        <FormItem field="pass">
                            <Button class="submit-btn btn-fill" type="primary" @click="handleSubmit" :loading="loading">
                                {{ t('submit') }}
                            </Button>
                        </FormItem>
                        <div class="link-box">
                            <a @click="router.push('/login')">{{ t('login.backToLogin') }}</a>
                        </div>
                    </Form>
                    <div v-else class="info-box msg-box">
                        <div v-if="successStatus">
                            <icon-check-circle-fill />
                            <p>{{ t('tip.changeEmailSuccessTip', { email: t('login.email') }) }}</p>
                            <Button class="submit-btn btn-fill" type="primary" @click="handleLogin">
                                {{ t('complete') }}
                            </Button>
                        </div>
                        <div v-else>
                            <icon-close-circle-fill />
                            <p>{{ errorMsg }}</p>
                        </div>
                    </div>
                </template>
            </CardBox>
        </div>
    </div>
</template>
<script>
import { defineComponent, reactive, inject, ref } from 'vue'
import CardBox from '@/components/CardBox.vue'
import { Form } from '@arco-design/web-vue'
import Header from '@/views/layout/Header.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as reg from '@/utils/formReg'
export default defineComponent({
    components: {
        Header,
        CardBox
    },
    setup() {
        const t = inject('$t')
        const http = inject('$http')
        const loading = ref(false)
        const formDom = ref(null || Form)
        const router = useRouter()
        const showFormBox = ref(true)
        const successStatus = ref(true)
        const errorMsg = ref('')
        const store = useStore()
        const formRule = {
            email: [
                { required: true, message: t('tip.emptyTip', { key: t('user.email') }) },
                { match: reg.emailReg, message: t('tip.emailRegTip1') },
                { maxLength: 50, message: t('tip.emailRegTip') }
            ]
        }
        const formData = reactive({
            email: ''
        })
        const handleLogin = () => {
            store.commit('clearUserData')
            router.push('/login')
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    loading.value = true
                    const param = { ...formData }
                    http.account
                        .postFindPasswordEmail(param)
                        .then(() => {
                            loading.value = false
                            formData.email = ''
                            showFormBox.value = false
                            successStatus.value = true
                        })
                        .catch(({ response }) => {
                            loading.value = false
                            errorMsg.value = response.data.message
                            showFormBox.value = false
                            successStatus.value = false
                        })
                }
            })
        }
        return {
            formData,
            formDom,
            formRule,
            loading,
            router,
            t,
            showFormBox,
            handleLogin,
            successStatus,
            handleSubmit,
            errorMsg
        }
    }
})
</script>
<style lang="less" scoped>
.forget-psw {
    margin: 30px 12%;
    background: #ffffff;
    min-width: 600px;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(177, 177, 177, 0.25);
    .form {
        margin: auto;
        padding-right: 60px;
        padding-top: 60px;
        padding-bottom: 100px;
        width: 460px;
        .submit-btn {
            margin-top: 10px;
            width: 100%;
        }
    }
    .link-box {
        padding-left: 100px;
        font-size: 14px;
        a {
            color: #4e80f4;
            text-decoration: none;
        }
    }
    .info-box {
        text-align: center;
        padding: 20px 0;
        .arco-icon {
            font-size: 40px;
            &.arco-icon-check-circle-fill {
                color: #0cb339;
            }
            &.arco-icon-close-circle-fill {
                color: red;
            }
        }
        p {
            margin: 20px auto;
            width: 270px;
            line-height: 24px;
        }
        .submit-btn {
            padding: 0 40px;
        }
    }
    .msg-box {
        padding: 60px;
    }
}
</style>

// 密码需包含大小写字母和数字,长度限制6~20
export const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/

// 用户名6~10位数字或字母，开头需为字母
export const usernameReg = /^[A-Za-z][A-Za-z0-9]{5,19}$/

// 邮箱限50个字符
export const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

// 团队名
export const teamNameReg = /^[A-Za-z0-9]{4,50}$/


import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
export default defineComponent({
    name: 'Lang',
    setup() {
        const { locale } = useI18n()
        const curLang = ref(locale.value)
        const router = useRouter()
        const switchLang = (lang: string) => {
            localStorage.setItem('lang', lang)
            router.go(0)
        }
        return {
            switchLang,
            curLang
        }
    }
})
